<template>
  <page-header-wrapper :title="false">
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="6" :sm="24">
              <a-form-item label="标题">
                <a-input placeholder="请输入标题" v-model="queryParam.title" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="上报人">
                <a-input placeholder="请输入上报人" v-model="queryParam.realName" allow-clear/>
              </a-form-item>
            </a-col>
            <a-col :md="6" :sm="24">
              <a-form-item label="手机号码">
                <a-input placeholder="请输入手机号码" v-model="queryParam.telephone" allow-clear/>
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="6" :sm="24">
                <a-form-item label="状态">
                  <a-select
                    v-model="queryParam.status"
                    placeholder="状态"
                    allow-clear
                  >
                    <a-select-option :key="-1" :value="-1">请选择</a-select-option>
                    <a-select-option v-for="item in statusList" :key="item.id" :value="item.id">{{ item.name }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="类型">
                  <a-select
                    v-model="queryParam.maintainType"
                    placeholder="请选择"
                    allow-clear
                  >
                    <a-select-option :key="0" :value="0">请选择</a-select-option>
                    <a-select-option v-for="item in maintainTypeList" :key="item.itemId" :value="item.itemId">{{ item.itemName }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="区域">
                  <a-cascader
                    :options="regionList"
                    placeholder="省-市-区(县)"
                    :field-names="{label: 'label', value: 'value', children: 'children' }"
                    :value="addDefaultRegion"
                    @change="changeAddRegion"
                  />
                </a-form-item>
              </a-col>
              <a-col :md="6" :sm="24">
                <a-form-item label="小区">
                  <a-select
                    placeholder="请选择小区"
                    v-model="queryParam.communityId"
                  >
                    <a-select-option :key="0" :value="0">请选择小区</a-select-option>
                    <a-select-option v-for="item in regionCommunityAdd" :key="item.keyId" :value="item.id">
                      {{ item.communityName }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="!advanced && 6 || 24" :sm="24">
              <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
                <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
                <a-button style="margin-left: 8px" @click="resetSearchForm()">重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'"/>
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <div class="table-operator">
        <a-button type="primary" icon="plus" @click="handleAdd">添加</a-button>
      </div>
      <s-table
        ref="table"
        size="default"
        rowKey="keyId"
        :columns="columns"
        :data="loadData"
      >
        <span slot="status" slot-scope="text, record">
          <a-tag color="#f50" v-if="record.status === 0">{{ record.statusStr }}</a-tag>
          <a-tag color="#2db7f5" v-else-if="record.status === 1">{{ record.statusStr }}</a-tag>
          <a-tag color="#87d068" v-else-if="record.status === 2">{{ record.statusStr }}</a-tag>
          <a-tag color="#108ee9" v-else-if="record.status === 3">{{ record.statusStr }}</a-tag>
          <a-tag color="#595959" v-else>{{ record.statusStr }}</a-tag>
        </span>
        <span slot="placeDetail" slot-scope="text, record">
          {{ record.countyStr }} {{ record.communityName }} {{ record.placeDetail }}
        </span>
        <span slot="action" slot-scope="text, record">
          <a @click="seeInfo(record.keyId)">查看</a>
          <a-divider type="vertical" />
          <a @click="handleEdit(record)">编辑</a>
          <a-divider type="vertical" />
          <a @click="dealData(record)">操作</a>
          <a-divider type="vertical" />
          <a-popconfirm title="确认要删除此记录吗？" @confirm="delMaintainApi(record.keyId)">
            <a>删除</a>
          </a-popconfirm>
        </span>
      </s-table>
      <see-maintain ref="seemaintain"></see-maintain>
      <deal-maintain ref="dealmaintain" @ok="handleOk"></deal-maintain>
      <maintain-info ref="maintaininfo" @ok="handleOk"></maintain-info>
    </a-card>
  </page-header-wrapper>
</template>
<script>
import { STable, Ellipsis } from '@/components'
import { delMaintain, maintainCondition, maintainList } from '@/api/maintain'
import { regionList } from '@/api/region'
import { regionCommunity } from '@/api/community'
import SeeMaintain from '@/views/maintain/components/SeeMaintain'
import DealMaintain from '@/views/maintain/components/DealMaintain'
import MaintainInfo from '@/views/maintain/components/MaintainInfo'
const columns = [
  {
    title: '编号',
    dataIndex: 'keyId',
    width: '80px'
  },
  {
    title: '标题',
    dataIndex: 'title'
  },
  {
    title: '上报人',
    dataIndex: 'reporterName'
  },
  {
    title: '手机号',
    dataIndex: 'telephone'
  },
  {
    title: '地址',
    dataIndex: 'placeDetail',
    scopedSlots: { customRender: 'placeDetail' }
  },
  {
    title: '维修类型',
    width: '100px',
    dataIndex: 'maintainTypeName'
  },
  {
    title: '状态',
    dataIndex: 'status',
    scopedSlots: { customRender: 'status' }
  },
  {
    title: '上报时间',
    dataIndex: 'createTime'
  },
  {
    title: '操作',
    width: '200px',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'ReportList',
  components: {
    STable,
    Ellipsis,
    SeeMaintain,
    DealMaintain,
    MaintainInfo
  },
  data () {
    this.columns = columns
    return {
      description: '',
      visible: false,
      form: this.$form.createForm(this, { name: 'addPhoneFrom' }),
      mdl: {},
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 }
      },

      // 高级搜索 展开/关闭
      advanced: false,
      // 查询参数
      queryParam: {
        title: '',
        realName: '',
        telephone: '',
        status: -1,
        communityId: 0,
        maintainType: 0,
        areaCode: 0
      },
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        const requestParameters = Object.assign({}, parameter, this.queryParam)
        return maintainList(requestParameters)
          .then(res => {
            return res.result
          })
      },
      regionList: [],
      regionCommunity: [],
      regionCommunityAdd: [],
      addDefaultRegion: [],
      statusList: [],
      maintainTypeList: []
    }
  },
  created () {
    this.regionListApi()
    this.maintainConditionApi()
  },
  watch: {
  },
  methods: {
    resetSearchForm () {
      this.queryParam = {
        title: '',
        realName: '',
        telephone: '',
        maintainType: 0,
        status: -1,
        communityId: 0,
        areaCode: 0
      }
      this.addDefaultRegion = []
    },
    handleEdit (record) {
      this.mdl = Object.assign({}, record)
      this.$refs.maintaininfo.initialData(this.mdl)
    },
    handleAdd () {
      var mainData = {
        keyId: 0,
        id: '',
        title: '',
        desc: '',
        communityId: 0,
        reportProvince: 0,
        reportCity: 0,
        reportCounty: 0,
        placeDetail: '',
        reporterId: undefined,
        reporterKeyId: 0,
        maintainType: 0,
        attachments: [],
        reporterName: '',
        telephone: '',
        countyStr: '',
        maintainTypeName: '',
        attachmentsList: []
      }
      this.$refs.maintaininfo.initialData(mainData)
    },
    seeInfo (id) {
      this.$refs.seemaintain.dataInfoApi(id)
    },
    dealData (data) {
      this.$refs.dealmaintain.ininData(data, this.statusList)
    },
    handleOk () {
      // 新增/修改 成功时，重载列表
      this.$refs.table.refresh()
    },
    toggleAdvanced () {
      this.advanced = !this.advanced
    },
    regionListApi () {
      const _this = this
      regionList().then((res) => {
        if (res.errorCode === 0) {
          _this.regionList = res.result
          console.log('regionList', _this.regionList)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    changeAddRegion (e) {
      this.addDefaultRegion = e
      // this.queryParam.provinceId = e[0] ? e[0] : 0
      // this.queryParam.cityId = e[1] ? e[1] : 0
      this.queryParam.areaCode = e[2] ? e[2] : 0

      this.regionCommunityApi(this.queryParam.areaCode, 1)
      this.queryParam.communityId = 0
    },
    regionCommunityApi (areaCode, type) {
      const _this = this
      regionCommunity({ areaCode: areaCode }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          if (type === 0) {
            _this.regionCommunity = res.result.data
          } else {
            _this.regionCommunityAdd = res.result.data
          }
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    delMaintainApi (id) {
      console.log(id)
      const _this = this
      delMaintain({ keyId: id }).then((res) => {
        if (res.errorCode === 0) {
          console.log(res)
          _this.$message.success('操作成功')
          _this.$refs.table.refresh()
        } else {
          _this.$message.error(res.errorMsg)
        }
      }).catch((err) => {
        console.log(err)
      })
    },
    maintainConditionApi () {
      const _this = this
      maintainCondition().then((res) => {
        if (res.errorCode === 0) {
          _this.statusList = res.result.statusList
          _this.maintainTypeList = res.result.maintainTypeList
        }
      }).catch((err) => {
        console.log(err)
      })
    }
  }
}
</script>
